/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/cloudinary_js@2.6.0/js/jquery.cloudinary.min.js
 * - /npm/bootstrap@4.3.0/dist/js/bootstrap.bundle.min.js
 * - /npm/jarallax@1.10.7/dist/jarallax.min.js
 * - /npm/jarallax@1.10.7/dist/jarallax-video.min.js
 * - /npm/balance-text@3.3.0/balancetext.min.js
 * - /npm/particles.js@2.0.0/particles.min.js
 * - /npm/amazon-cognito-identity-js@3.0.15/dist/amazon-cognito-identity.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
